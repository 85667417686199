<template>
  <Auth />
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">Import Data Paket Melalui File .CSV</h5>
            <p class="mb-0 text-sm">
              Silahkan download untuk panduan upload file import.
              <a href="#" class="text-primary">Download disini</a>
            </p>
          </div>
          <div
            class="card-body"
            style="
              border-top: 1px solid #eeeeee;
              padding-top: 100px;
              padding-bottom: 100px;
            "
          >
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="box-link align-middle text-center">
                      Upload file disini
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Auth from "@/components/auth/Auth";
import { ElLoading } from "element-plus";
export default {
  name: "ImportSendPackages",
  components: {
    Auth,
  },
  data() {
    return {
      urlimport: "",
    };
  },
  created() {
    console.log("Ok");
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });
      return loading;
    },
  },
};
</script>
<style scoped>
.box-link {
  height: 250px;
  border: 2px dashed #cccccc;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-link:hover {
  cursor: pointer;
  border: 2px dashed #6c6be4;
}
.box-link:hover h3 {
  color: #6c6be4;
}
.box-link:hover h4 {
  color: #6c6be4;
}
</style>
